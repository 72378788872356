import {
  AdditionalDetails,
  CustomerDetailsSection,
  Layout,
} from "@twocontinents/dashboard/shared";
import { useParams } from "react-router-dom";

import { useGetBookingOrder } from "../data-access";
import { BookingOrderDetails } from "./booking-order-details";
import { OrderDetailsSkeleton } from "./order-details-skeleton";

export const BookingOrderShowPage = () => {
  const { id } = useParams<{ id: string }>();

  const { order, isLoading } = useGetBookingOrder(Number(id));

  const shouldShowOrder = !isLoading && !!order;

  return (
    <Layout
      header={{
        title: "Booking Order",
        description: `Manage booking order #${id}`,
      }}
    >
      {isLoading && <OrderDetailsSkeleton />}
      {shouldShowOrder && (
        <>
          <BookingOrderDetails order={order} />
          {order.orderAdditionalDetails && (
            <AdditionalDetails
              additionalDetails={order.orderAdditionalDetails}
            />
          )}
          <CustomerDetailsSection
            orderId={order.id}
            customerDetails={order.customerDetails}
            companyDetails={order.companyDetails}
            invoiceType={order.invoiceType}
          />
        </>
      )}
    </Layout>
  );
};
