import { zodResolver } from "@hookform/resolvers/zod";
import { AttractionGroup } from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAttractionGroupAvailabilitySource } from "../../../data-access";
import { useAttractionGroups } from "../../../hooks";
import { AttractionGroupEntity, AvailabilitySource } from "../../../types";

const AvailabilitySourceSettingsSchema = z.object({
  groupId: z.number(),
  availabilitySource: z.nativeEnum(AvailabilitySource),
});

type AvailabilitySourceSettingsSchema = z.infer<
  typeof AvailabilitySourceSettingsSchema
>;

export const useAvailabilitySourceSettings = (
  attractionId: number,
  attractionGroups: AttractionGroup[],
) => {
  const { updateAttractionGroupAvailabilitySource, isPending } =
    useUpdateAttractionGroupAvailabilitySource();

  const form = useForm<AvailabilitySourceSettingsSchema>({
    resolver: zodResolver(AvailabilitySourceSettingsSchema),
  });

  const { handleSubmit, watch, setValue } = form;

  const onSubmit = handleSubmit(({ groupId, ...body }) => {
    updateAttractionGroupAvailabilitySource({
      attractionId,
      groupId,
      body,
    });
  });

  const groupId = watch("groupId");
  const availabilitySource = watch("availabilitySource");

  const onGroupChange = (selectedGroup: AttractionGroupEntity) => {
    setValue("availabilitySource", selectedGroup.availabilitySource);
  };

  const { selectedGroup } = useAttractionGroups({
    attractionGroups,
    groupId,
    onGroupChange,
  });

  const sourceChangeableAttractionGroups = attractionGroups.filter(
    (group) => group.canAvailableSourceBeChanged,
  );

  const canAnyGroupBeChangedToExternal =
    sourceChangeableAttractionGroups.length > 0;

  return {
    form,
    onSubmit,
    isPending,
    availabilitySource,
    canAnyGroupBeChangedToExternal,
    sourceChangeableAttractionGroups,
  };
};
