import { DateFormatter } from "@twocontinents/shared";
import { useFormContext } from "react-hook-form";

import {
  CalendarExtraOptionAvailability,
  ExtraOptionsAvailabilityForm,
} from "../availability-settings/use-extra-options-availability-settings";

export const useCalendar = (
  allAvailabilitiesMap: Map<string, CalendarExtraOptionAvailability[]>,
) => {
  const { setValue } = useFormContext<ExtraOptionsAvailabilityForm>();

  const isDateAvailable = (date: Date) => {
    const availabilities =
      allAvailabilitiesMap.get(DateFormatter.formatToYYYYMMDD(date)) ?? [];
    const mutatedAvailabilities = availabilities.filter(
      (av) => av.id === undefined,
    );
    const fetchedAvailabilities = availabilities.filter(
      (av) => av.id !== undefined,
    );

    const allAvailabilities = [
      ...mutatedAvailabilities,
      ...fetchedAvailabilities.filter(
        (av) =>
          !mutatedAvailabilities.some(
            (mutatedAvail) => mutatedAvail.date === av.date,
          ),
      ),
    ];

    const notAvailable = allAvailabilities.filter(
      (availability) => !availability.available,
    );

    return notAvailable.length === 0;
  };

  const selectCalendarDay = (date: Date) => {
    setValue("date", DateFormatter.formatToYYYYMMDD(date));
  };

  return {
    selectCalendarDay,
    isDateAvailable,
  };
};
