import {
  AttractionGroup,
  AttractionGroupSelect,
  Button,
  Checkbox,
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { Calendar } from "../calendar";
import { TimeSelect } from "../time-select";
import { useAvailabilitySettings } from "./use-availability-settings";

interface AvailabilitySettingsProps {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const AvailabilitySettings = ({
  attractionId,
  attractionGroups,
}: AvailabilitySettingsProps) => {
  const {
    form,
    onSubmit,
    isPending,
    formRef,
    mutatedAvailabilities,
    isGroupTimed,
    saveMutatedAvailabilities,
    onSubmitUnavailable,
    times,
    getAvailableSlotsByTime,
    addButtonDisabled,
    selectedDate,
    isExternalAvailabilitySource,
  } = useAvailabilitySettings(attractionGroups, attractionId);

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Availability settings</SettingCard.Title>
        <SettingCard.Description>
          Change group's availability
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit} ref={formRef}>
          <Form.Field
            control={form.control}
            name="groupId"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label>Group</FormItem.Label>
                  <FormItem.Control>
                    <AttractionGroupSelect
                      attractionGroups={attractionGroups}
                      onValueChange={field.onChange}
                    />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <div className={"flex gap-40 items-center mt-10 relative"}>
            {isExternalAvailabilitySource && (
              <div className="text-lg font-medium absolute h-full w-full bg-slate-800/95 top-0 left-0 z-10 cursor-not-allowed flex items-center justify-center">
                Availability source has to be Internal to be able to change
                availability
              </div>
            )}

            <div className={"w-full max-w-md"}>
              <Form.Fields>
                <Form.Field
                  control={form.control}
                  name={`availability.date`}
                  render={({ field }) => (
                    <FormItem>
                      <FormItem.Row>
                        <FormItem.Label>Date</FormItem.Label>
                        <FormItem.Control>
                          <Input {...field} type={"date"} />
                        </FormItem.Control>
                      </FormItem.Row>
                      <FormItem.Message />
                    </FormItem>
                  )}
                />
                {isGroupTimed && (
                  <TimeSelect
                    key={selectedDate}
                    getAvailableSlotsByTime={getAvailableSlotsByTime}
                    times={times}
                  />
                )}
                <Form.Field
                  control={form.control}
                  name={`availability.slotsAvailable`}
                  render={({ field }) => (
                    <FormItem>
                      <FormItem.Row>
                        <FormItem.Label>Slots available</FormItem.Label>
                        <FormItem.Control>
                          <Input {...field} type={"number"} />
                        </FormItem.Control>
                      </FormItem.Row>
                      <FormItem.Message />
                    </FormItem>
                  )}
                />

                <FormItem.Row>
                  <Form.Field
                    control={form.control}
                    name={`times`}
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label>Times</FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} type={"number"} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />{" "}
                  <Form.Field
                    control={form.control}
                    name={`days`}
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label>Days</FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} type={"number"} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                </FormItem.Row>
              </Form.Fields>
              <div className={"flex justify-end mt-6 gap-2"}>
                <Button
                  type={"button"}
                  variant="destructive"
                  onClick={onSubmitUnavailable}
                >
                  Not available
                </Button>
                <Button
                  type={"submit"}
                  variant="secondary"
                  disabled={addButtonDisabled}
                >
                  Add
                </Button>
              </div>
            </div>

            <Calendar
              attractionGroups={attractionGroups}
              mutatedAvailabilities={mutatedAvailabilities}
              attractionId={attractionId}
            />
          </div>
        </form>

        <SettingCard.Footer>
          <SettingCard.Description>
            This setting will change variant's description only on the
            dashboard.
          </SettingCard.Description>
          <SettingCard.Button
            disabled={isExternalAvailabilitySource}
            onClick={saveMutatedAvailabilities}
            isLoading={isPending}
          >
            Submit
          </SettingCard.Button>
        </SettingCard.Footer>
      </Form>
    </SettingCard>
  );
};
