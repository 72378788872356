import {
  Form,
  FormItem,
  Input,
  MultiSelect,
  MultiSelectOptionGroup,
  MultiSelectOptionType,
  ShopOffering,
} from "@twocontinents/dashboard/shared";
import { useFormContext } from "react-hook-form";

import { AddCouponFormSchema } from "./use-add-coupon-form";

interface ProductsMultiselectProps {
  shopOffering: ShopOffering;
  variantsOptions: MultiSelectOptionGroup[];
  apartmentsOptions: MultiSelectOptionGroup[];
  packageHolidayOptions: MultiSelectOptionGroup[];
  selectedVariants: MultiSelectOptionType[];
  setSelectedVariants: (value: MultiSelectOptionType[]) => void;
  selectedApartments: MultiSelectOptionType[];
  handleApartmentsChange: (value: MultiSelectOptionType[]) => void;
  selectedPackageHolidays: MultiSelectOptionType[];
  handlePackageHolidayChange: (value: MultiSelectOptionType[]) => void;
}

export const ProductsMultiselect = ({
  shopOffering,
  setSelectedVariants,
  selectedApartments,
  selectedPackageHolidays,
  packageHolidayOptions,
  selectedVariants,
  variantsOptions,
  apartmentsOptions,
  handleApartmentsChange,
  handlePackageHolidayChange,
}: ProductsMultiselectProps) => {
  const form = useFormContext<AddCouponFormSchema>();
  if (shopOffering === ShopOffering.ATTRACTION) {
    return (
      <Form.Field
        control={form.control}
        name="attractionVariantIds"
        render={() => (
          <FormItem>
            <FormItem.Row>
              <FormItem.Label className="min-w-64">Variants</FormItem.Label>
              <MultiSelect
                options={variantsOptions}
                selected={selectedVariants}
                onChange={setSelectedVariants}
                placeholder="Choose Attraction Variants"
                className=" w-96"
              />
            </FormItem.Row>
          </FormItem>
        )}
      />
    );
  }
  if (shopOffering === ShopOffering.STAY) {
    return (
      <>
        <Form.Field
          control={form.control}
          name="apartmentIds"
          render={() => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label className="min-w-64">Apartments</FormItem.Label>
                <MultiSelect
                  options={apartmentsOptions}
                  selected={selectedApartments}
                  onChange={handleApartmentsChange}
                  placeholder="Choose apartment"
                  className=" w-96"
                />
              </FormItem.Row>
            </FormItem>
          )}
        />
        <Form.Field
          control={form.control}
          name="minimumStays"
          render={({ field }) => (
            <FormItem>
              <FormItem.Row>
                <FormItem.Label className="min-w-64">
                  Minimum Stay
                </FormItem.Label>
                <Input {...field} placeholder="6" className="w-fit" />
              </FormItem.Row>
            </FormItem>
          )}
        />
      </>
    );
  }
  return (
    <Form.Field
      control={form.control}
      name="packageHolidayIds"
      render={() => (
        <FormItem>
          <FormItem.Row>
            <FormItem.Label className="min-w-64">
              Package holidays
            </FormItem.Label>
            <MultiSelect
              options={packageHolidayOptions}
              selected={selectedPackageHolidays}
              onChange={handlePackageHolidayChange}
              placeholder="Choose package holiday"
              className=" w-96"
            />
          </FormItem.Row>
        </FormItem>
      )}
    />
  );
};
