import { zodResolver } from "@hookform/resolvers/zod";
import {
  AttractionGroup,
  AttractionVariantDetailedResponse,
} from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAttractionVariant } from "../../../data-access";
import { useAttractionVariants } from "../../../hooks";

const TimePriorToReservationSettingsSchema = z.object({
  variantId: z.number().nonnegative(),
  minDaysPriorToReservation: z.coerce.number().nonnegative(),
  maxDaysInAdvanceForReservation: z.coerce.number().nonnegative(),
  timePriorToReservation: z.string(),
});

type TimePriorToReservationSettingsSchema = z.infer<
  typeof TimePriorToReservationSettingsSchema
>;

export const useTimePriorToReservationSettings = (
  attractionId: number,
  attractionGroups: AttractionGroup[],
) => {
  const { updateVariant, isPending } = useUpdateAttractionVariant();

  const form = useForm<TimePriorToReservationSettingsSchema>({
    resolver: zodResolver(TimePriorToReservationSettingsSchema),
  });

  const { handleSubmit, watch, setValue } = form;

  const onSubmit = handleSubmit(({ variantId, ...body }) => {
    updateVariant({
      attractionId,
      variantId,
      body,
    });
  });

  const variantId = watch("variantId");
  const onVariantChange = (
    selectedVariant: AttractionVariantDetailedResponse,
  ) => {
    setValue("timePriorToReservation", selectedVariant.timePriorToReservation);
    setValue(
      "minDaysPriorToReservation",
      selectedVariant.minDaysPriorToReservation,
    );
    setValue(
      "maxDaysInAdvanceForReservation",
      selectedVariant.maxDaysInAdvanceForReservation,
    );
  };
  useAttractionVariants({
    variantId,
    attractionGroups,
    onVariantChange,
  });

  return {
    form,
    onSubmit,
    isPending,
  };
};
