import {
  BatchOrdersUpdateButtons,
  Button,
  Checkbox,
  StatusIndicator,
  Table,
  routes,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { TravelAgencyIcon } from "@twocontinents/shared-ui";
import { AlertTriangle } from "lucide-react";
import { Link } from "react-router-dom";

import { AttractionOrderBase } from "../../../types";
import { OrderReservationsListDropdown } from "./order-reservations-list-dropdown";
import { useOrdersTable } from "./use-orders-table";

interface OrdersTableProps {
  orders: AttractionOrderBase[];
}

export const OrdersTable = ({ orders }: OrdersTableProps) => {
  const {
    areAllOrdersSelected,
    areDropdownsOpened,
    mappedOrders,
    openDropdown,
    selectedOrders,
    handleDropdownOpenChange,
    handleAllOrdersCheckboxChange,
    handleSingleOrderCheckboxChange,
    setSelectedOrders,
  } = useOrdersTable(orders);

  return (
    <>
      <Table className="  bg-slate-950 ">
        <Table.Header className="w-full rounded-3xl ">
          <Table.Row className="border-slate-700">
            <Table.Head className="w-[32px]">
              <Checkbox
                id="select-all"
                className="data-[state=checked]:bg-slate-200 border-slate-200 data-[state=checked]:text-slate-800 w-6 h-6"
                checked={areAllOrdersSelected}
                onCheckedChange={handleAllOrdersCheckboxChange}
              />
            </Table.Head>
            <Table.Head className="w-36 py-5">ID</Table.Head>
            <Table.Head className="py-5">Order date</Table.Head>
            <Table.Head className="py-5">Attraction date</Table.Head>
            <Table.Head className="py-5">Full name</Table.Head>
            <Table.Head className="py-5">Locale</Table.Head>
            <Table.Head className="py-5">Open</Table.Head>
            <Table.Head className="py-5">Payment status</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {mappedOrders.map((order, i) => {
            const formattedTimePurchased = DateFormatter.formatToDDMMYYYYHHmm(
              order.timePurchased,
            );
            const formattedReservationDateTime =
              DateFormatter.formatDateTimeToDDMMYYYYHHmm(
                order.reservationDate,
                order.reservationTime,
              );
            const fullName = (
              <>
                {order.customerDetails.name} {order.customerDetails.surname}
              </>
            );
            const hasAdditionalDetails =
              order.orderAdditionalDetails &&
              order.orderAdditionalDetails.trim() !== "";

            const isTravelAgency = order.agentId;

            const paymentStatusLabel = order.paymentConfirmed
              ? "Paid"
              : "Unpaid";
            const paymentStatusColorVariant = order.paymentConfirmed
              ? "positive"
              : "negative";

            return (
              <Table.Row
                key={order.id}
                className=" border-slate-700 hover:bg-slate-900"
              >
                <Table.Cell>
                  <Checkbox
                    id={`select-${order.id}`}
                    className="data-[state=checked]:bg-slate-200 data-[state=checked]:text-slate-800 w-6 h-6 border-slate-200"
                    checked={selectedOrders.includes(order.id)}
                    onCheckedChange={(checked: boolean) => {
                      handleSingleOrderCheckboxChange(checked, order.id);
                    }}
                  />
                </Table.Cell>
                <Table.Cell
                  className="cursor-pointer"
                  onClick={() => openDropdown(i)}
                >
                  <OrderReservationsListDropdown
                    setIsDropdownOpened={(opened: boolean) => {
                      handleDropdownOpenChange(opened, i);
                    }}
                    isDropdownOpened={areDropdownsOpened[i]}
                    orderId={order.id}
                    reservations={order.reservations}
                  />
                </Table.Cell>
                <Table.Cell
                  className="cursor-pointer"
                  onClick={() => openDropdown(i)}
                >
                  {formattedTimePurchased}{" "}
                </Table.Cell>
                <Table.Cell
                  className="cursor-pointer"
                  onClick={() => openDropdown(i)}
                >
                  {formattedReservationDateTime}{" "}
                </Table.Cell>
                <Table.Cell
                  className="cursor-pointer"
                  onClick={() => openDropdown(i)}
                >
                  {fullName}
                </Table.Cell>
                <Table.Cell
                  className="cursor-pointer"
                  onClick={() => openDropdown(i)}
                >
                  {order.customerDetails.locale}
                </Table.Cell>
                <Table.Cell
                  className="cursor-pointer"
                  onClick={() => {
                    openDropdown(i);
                  }}
                >
                  <Link
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    to={routes.attractions.orders.show.url(order.id)}
                  >
                    <Button variant="outline" className="relative">
                      Open
                      {hasAdditionalDetails && (
                        <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                          <AlertTriangle size={20} color="#002aff" />
                        </div>
                      )}
                      {isTravelAgency && (
                        <div className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2">
                          <TravelAgencyIcon />
                        </div>
                      )}
                    </Button>
                  </Link>
                </Table.Cell>{" "}
                <Table.Cell
                  className="cursor-pointer"
                  onClick={() => openDropdown(i)}
                >
                  <StatusIndicator status={paymentStatusColorVariant}>
                    {paymentStatusLabel}
                  </StatusIndicator>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <BatchOrdersUpdateButtons
        selectedOrderIds={selectedOrders}
        setSelectedOrderIds={setSelectedOrders}
      />
    </>
  );
};
