import { AttractionGroup } from "@twocontinents/dashboard/shared";
import { Layout } from "@twocontinents/dashboard/shared";

import { AvailabilitySettings } from "./availability-settings";
import { AvailabilitySourceSettings } from "./availability-source-settings";
import { DefaultSlotsAvailableSettings } from "./default-slots-available-settings";
import { DescriptionSettings } from "./description-settings";
import { ExtraOptionsAvailabilitySettings } from "./extra-options-availability-settings";

interface GroupsSettings {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const GroupsSettings = ({
  attractionId,
  attractionGroups,
}: GroupsSettings) => {
  return (
    <Layout.Content>
      <DescriptionSettings
        attractionId={attractionId}
        attractionGroups={attractionGroups}
      />
      <AvailabilitySourceSettings
        attractionId={attractionId}
        attractionGroups={attractionGroups}
      />
      <DefaultSlotsAvailableSettings
        attractionId={attractionId}
        attractionGroups={attractionGroups}
      />
      <AvailabilitySettings
        attractionId={attractionId}
        attractionGroups={attractionGroups}
      />
      <ExtraOptionsAvailabilitySettings
        attractionId={attractionId}
        attractionGroups={attractionGroups}
      />
    </Layout.Content>
  );
};
