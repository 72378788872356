import { zodResolver } from "@hookform/resolvers/zod";
import { useOpened } from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateBlackListEntry } from "../data-access";

const CreateBlacklistEntrySchema = z.object({
  ipAddress: z.string(),
  blacklisted: z.boolean().default(true),
});

type CreateBlacklistEntrySchema = z.infer<typeof CreateBlacklistEntrySchema>;

export const useCreateBlacklistEntryModal = () => {
  const { opened, open, close } = useOpened();

  const { createBlackListEntry, isPending } = useCreateBlackListEntry();

  const form = useForm<CreateBlacklistEntrySchema>({
    resolver: zodResolver(CreateBlacklistEntrySchema),
    mode: "onBlur",
    defaultValues: {
      blacklisted: true,
    },
  });

  const onSubmit = form.handleSubmit(async (data) => {
    createBlackListEntry(data, {
      onSuccess: () => {
        form.reset();
        close();
      },
    });
  });

  return {
    form,
    onSubmit,
    opened,
    open,
    close,
    isPending,
  };
};
