import { zodResolver } from "@hookform/resolvers/zod";
import { PencilLine } from "lucide-react";
import { useForm } from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import { z } from "zod";

import { useUpdateCustomerDetails } from "../../data-access/update-customer-details";
import {
  AlertDialog,
  Button,
  Form,
  FormItem,
  Input,
  buttonVariants,
} from "../shadcn";

const UpdateCustomerEmailFormSchema = z.object({
  customerEmail: z.string().email(),
});

type UpdateCustomerEmailFormValues = z.infer<
  typeof UpdateCustomerEmailFormSchema
>;

interface UpdateCustomerEmailDialogProps {
  orderId: number;
  email: string;
}

export const UpdateCustomerEmailDialog = ({
  orderId,
  email,
}: UpdateCustomerEmailDialogProps) => {
  const openAlertDialog = useBoolean(false);
  const { updateCustomer, isPending } = useUpdateCustomerDetails(orderId);
  const form = useForm<UpdateCustomerEmailFormValues>({
    defaultValues: {
      customerEmail: email,
    },
    resolver: zodResolver(UpdateCustomerEmailFormSchema),
  });

  const handleFormSubmit = form.handleSubmit(async (values) => {
    updateCustomer(
      {
        customerEmail: values.customerEmail,
      },
      {
        onSuccess: () => {
          openAlertDialog.setValue(false);
        },
      },
    );
  });

  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button variant="outline" className="ml-4 px-2 py-0">
          <PencilLine size={16} />
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content className="max-w-3xl w-fit">
        <AlertDialog.Header>
          <AlertDialog.Title>Update customer email</AlertDialog.Title>
          <AlertDialog.Description>
            Are you sure you want to update email of this customer?
          </AlertDialog.Description>
        </AlertDialog.Header>
        <Form {...form}>
          <form onSubmit={handleFormSubmit}>
            <Form.Field
              control={form.control}
              name="customerEmail"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Email</FormItem.Label>
                    <FormItem.Control>
                      <Input value={field.value} onChange={field.onChange} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <AlertDialog.Footer className="my-4">
              <AlertDialog.Cancel
                type="button"
                className={buttonVariants({ variant: "destructive" })}
              >
                Cancel
              </AlertDialog.Cancel>

              <Button isLoading={isPending} type="submit" variant="outline">
                Update
              </Button>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
