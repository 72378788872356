import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidateGetAttractionCrewAvailability } from "./get-attraction-crew-availability";
import { useInvalidateGetAttractionGroupAvailability } from "./get-attraction-group-availabilities";

type Body = components["schemas"]["UpdateAttractionGroupAvailabilityRequest"];

interface Props {
  attractionId: number;
  groupId: number;
  body: Body;
}

// eslint-disable-next-line max-lines-per-function
export const useUpdateAttractionGroupAvailabilities = () => {
  const apiClient = usePrivateApiClient();
  const { invalidateGroupAvailability } =
    useInvalidateGetAttractionGroupAvailability();
  const { invalidateCrewAvailability } =
    useInvalidateGetAttractionCrewAvailability();

  const { mutate: updateGroupAvailability, isPending } = useMutation({
    mutationFn: ({ groupId, body: { availabilities } }: Props) =>
      Promise.all([
        apiClient.POST(`/private/attractions/groups/{id}/availability`, {
          params: {
            path: { id: groupId },
          },
          body: { availabilities },
        }),
      ]).then((results) => {
        const errorResult = results.find((result) => result.error);
        if (errorResult && errorResult.error) {
          throw new Error(errorResult.error.message);
        }

        return results;
      }),

    onSuccess: async (_, { groupId, attractionId }) => {
      toast.success("Group availability has been updated successfully.");
      await invalidateGroupAvailability(groupId);
      await invalidateCrewAvailability(attractionId);
    },
    onError: () => {
      toast.error("Group availability could not be updated");
    },
  });

  return { updateGroupAvailability, isPending };
};
