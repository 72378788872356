import {
  Tour,
  TourFormatter,
} from "@twocontinents/dashboard/attractions/shared";
import {
  AlertDialog,
  AttractionCombobox,
  AttractionGroupSelect,
  Button,
  DateRangePicker,
  Form,
  FormItem,
  Label,
  NOT_SELECTED_ID,
  buttonVariants,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";

import { TourCombobox } from "./tour-combobox";
import { useMoveTourParticipantsDialog } from "./use-move-tour-participants-dialog";

interface Props {
  tour: Tour;
  participantIds: number[];
  resetSelectedParticipants: () => void;
}

export const MoveTourParticipantsDialog = ({
  tour,
  participantIds,
  resetSelectedParticipants,
}: Props) => {
  const {
    attractionGroups,
    attractions,
    comboboxTours,
    selectedAttractionId,
    form,
    isPending,
    handleFormSubmit,
    openAlertDialog,
    setTargetTourId,
    targetTourId,
    dateTo,
    setDateTo,
  } = useMoveTourParticipantsDialog(
    tour,
    participantIds,
    resetSelectedParticipants,
  );

  const batchMove = participantIds.length > 1;

  const formattedDateTo = dateTo
    ? DateFormatter.formatToNativeDate(dateTo)
    : undefined;

  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button variant="outline">
          {batchMove ? "Move selected" : "Move"}
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content className="max-w-3xl w-fit">
        <AlertDialog.Header>
          <AlertDialog.Title>
            Move {batchMove ? "participants" : "participant"} to another tour
          </AlertDialog.Title>
          <AlertDialog.Description>
            Select the new tour to move{" "}
            {batchMove ? "participants" : "participant"}
          </AlertDialog.Description>
        </AlertDialog.Header>
        <Form {...form}>
          <form onSubmit={handleFormSubmit}>
            <div className="flex flex-col gap-6  border border-slate-700 p-4 rounded-lg mb-4">
              <p className="text-white">Change tour</p>

              <Form.Field
                control={form.control}
                name="dateFrom"
                render={({ field }) => {
                  return (
                    <FormItem.Row>
                      <Label className="min-w-40">Date</Label>
                      <DateRangePicker
                        dateFrom={
                          field.value ? new Date(field.value) : undefined
                        }
                        setDateFrom={(date) =>
                          field.onChange(
                            date
                              ? DateFormatter.formatToYYYYMMDD(date)
                              : undefined,
                          )
                        }
                        dateTo={formattedDateTo}
                        setDateTo={setDateTo}
                      />
                    </FormItem.Row>
                  );
                }}
              />

              <Form.Field
                control={form.control}
                name="attractionId"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label>Attraction</FormItem.Label>
                      <AttractionCombobox
                        attractions={attractions}
                        setSelectedOption={(option) => {
                          field.onChange(option?.id ?? NOT_SELECTED_ID);
                        }}
                        selectedId={field.value}
                      />
                    </FormItem.Row>
                  </FormItem>
                )}
              />

              {selectedAttractionId && (
                <Form.Field
                  control={form.control}
                  name="attractionGroupId"
                  render={({ field }) => (
                    <FormItem>
                      <FormItem.Row>
                        <FormItem.Label>Attraction group</FormItem.Label>
                        <AttractionGroupSelect
                          attractionGroups={attractionGroups}
                          onValueChange={field.onChange}
                        />
                      </FormItem.Row>
                    </FormItem>
                  )}
                />
              )}

              <TourCombobox
                selectedTourId={targetTourId}
                onTourChange={setTargetTourId}
                tours={comboboxTours}
              />

              <p className="text-white font-bold">
                Current tour:{" "}
                {TourFormatter.formatWithParticipantsDetails(tour)}
              </p>
            </div>
            <AlertDialog.Footer>
              <AlertDialog.Cancel
                className={buttonVariants({ variant: "destructive" })}
              >
                Cancel
              </AlertDialog.Cancel>

              <Button isLoading={isPending} type="submit" variant="outline">
                Move
              </Button>
            </AlertDialog.Footer>
          </form>
        </Form>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
