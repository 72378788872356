import { AttractionGroup } from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { useFormContext } from "react-hook-form";

import { useGetAttractionCrewAvailability } from "../../../../data-access";
import { useAttractionGroups } from "../../../../hooks";
import {
  CalendarDayAvailabilityList,
  DateAvailability,
} from "../../../../types";
import { AvailabilityForm } from "../availability-settings/use-availability-settings";

export const useCalendar = (
  attractionGroups: AttractionGroup[],
  mutatedAvailabilities: DateAvailability[],
  attractionId: number,
) => {
  const { setValue, watch } = useFormContext<AvailabilityForm>();
  const groupId = watch("groupId");
  const activeDate = new Date(watch("availability.date"));

  const { selectedGroup } = useAttractionGroups({
    attractionGroups,
    groupId,
  });

  const areAllDatesUnavailable = selectedGroup?.defaultSlotsAvailable === 0;

  const { crewAvailability } = useGetAttractionCrewAvailability(attractionId);

  const hasAnyCrew = crewAvailability?.length > 0;

  const availabilities: DateAvailability[] =
    selectedGroup?.availabilities.map(
      (availability) =>
        new DateAvailability({
          ...availability,
          extraOptions: [],
        }),
    ) ?? [];

  const dateAvailabilityList = new CalendarDayAvailabilityList(
    availabilities,
    crewAvailability,
  );

  const mutatedDates: Date[] = mutatedAvailabilities.map(
    (availability) => new Date(availability.date),
  );

  const isDateMutated = (date: Date) =>
    mutatedDates.some(
      (mutatedDate) =>
        DateFormatter.formatToYYYYMMDD(mutatedDate) ===
        DateFormatter.formatToYYYYMMDD(date),
    );

  const getDateAvailability = (date: Date) => {
    return dateAvailabilityList.getDateAvailability(
      DateFormatter.formatToYYYYMMDD(date),
    );
  };

  const selectCalendarDay = (date: Date) => {
    setValue("availability.date", DateFormatter.formatToYYYYMMDD(date));
    setValue("availability.time", undefined);
  };

  return {
    mutatedDates,
    selectCalendarDay,
    areAllDatesUnavailable,
    activeDate,
    dateAvailabilityList,
    hasAnyCrew,
    isDateMutated,
    getDateAvailability,
  };
};
