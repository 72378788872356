import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { GetBlackListEntriesFilters } from "../types";

const queryKey = (filters?: GetBlackListEntriesFilters) => [
  "private-black-list-entries",
  filters,
];

export const useGetBlackListEntries = (
  filters?: GetBlackListEntriesFilters,
) => {
  const apiClient = usePrivateApiClient();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/black-list/", { params: { query: filters } })
        .then((res) => res.data),
    queryKey: queryKey(filters),
  });

  return {
    blackListEntries: data?.blackListEntries ?? [],
    totalPages: data?.pagination?.totalPages ?? 0,
    totalElements: data?.pagination?.totalElements ?? 0,
    isLoading,
  };
};

export const useInvalidateGetBlackListEntries = () => {
  const queryClient = useQueryClient();

  const invalidate = (filters?: GetBlackListEntriesFilters) => {
    queryClient.invalidateQueries({ queryKey: queryKey(filters) });
  };

  return { invalidate };
};
