import { useGetPackageHolidaysCoupons } from "../../data-access";
import { useCouponFiltersQueryParams } from "../../hooks";

export const usePackageHolidaysCouponsTab = () => {
  const { query } = useCouponFiltersQueryParams();
  const { coupons, pagination, arePaginatedCouponsLoading } =
    useGetPackageHolidaysCoupons({
      page: query.page,
      size: query.size,
    });

  return {
    coupons,
    pagination,
    arePaginatedCouponsLoading,
  };
};
