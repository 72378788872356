import {
  AttractionGroup,
  AttractionVariantDetailedResponse,
} from "@twocontinents/dashboard/shared";
import { useEffect, useMemo } from "react";

interface UseAttractionVariants {
  attractionGroups: AttractionGroup[];
  variantId: number;
  onVariantChange: (
    attractionVariant: AttractionVariantDetailedResponse,
  ) => void;
}

export const useAttractionVariants = ({
  attractionGroups,
  variantId,
  onVariantChange,
}: UseAttractionVariants) => {
  const variants = useMemo(
    () => attractionGroups.flatMap((group) => group.attractionVariants),
    [attractionGroups],
  );
  const selectedVariant = useMemo(
    () => variants.find((variant) => variant.id === variantId),
    [variantId, variants],
  );

  useEffect(() => {
    if (selectedVariant) {
      onVariantChange(selectedVariant);
    }
    // Disable for correct app behaviour
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant]);

  return {
    attractionGroups,
    selectedVariant,
  };
};
