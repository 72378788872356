import {
  useGetAttractions,
  useOrderFiltersQueryParams,
} from "@twocontinents/dashboard/shared";
import {
  ALL_ITEMS_ID,
  AttractionOption,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import dayjs from "dayjs";
import { useBoolean } from "usehooks-ts";

export const useOrderFilters = () => {
  const { query, setQuery } = useOrderFiltersQueryParams();

  const { attractions } = useGetAttractions();

  const selectedId = query.attractionId ?? -1;

  const attractionsOptions =
    attractions?.map((attraction) => ({
      description: attraction.description,
      id: attraction.id,
    })) ?? [];

  const { reservationDate } = query;

  const handleAttractionComboboxSelect = (value?: AttractionOption) => {
    if (value?.id === ALL_ITEMS_ID) {
      setQuery({ attractionId: undefined });
      return;
    }
    setQuery({ attractionId: value?.id });
  };

  const setDateRangeAsThisMonth = () => {
    openDateRangePicker();

    const today = dayjs();
    const firstDayOfThisMonth = today.startOf("month");
    const lastDayOfThisMonth = today.endOf("month");

    setQuery({
      reservationDateFrom: DateFormatter.formatToYYYYMMDD(firstDayOfThisMonth),
      reservationDateTo: DateFormatter.formatToYYYYMMDD(lastDayOfThisMonth),
    });
  };

  const handleReservationDateChange = (date?: Date) => {
    setQuery({
      reservationDate: date ? dayjs(date).format("YYYY-MM-DD") : undefined,
    });
  };

  const setReservationDateFrom = (date: Date | undefined) => {
    setQuery({
      reservationDateFrom: date
        ? DateFormatter.formatToYYYYMMDD(date)
        : undefined,
    });
  };

  const setReservationDateTo = (date: Date | undefined) => {
    setQuery({
      reservationDateTo: date
        ? DateFormatter.formatToYYYYMMDD(date)
        : undefined,
    });
  };

  const {
    value: showRangePicker,
    toggle: toggleShowRangePicker,
    setTrue: openDateRangePicker,
  } = useBoolean(false);

  const toggleRangePicker = () => {
    toggleShowRangePicker();
    setQuery({
      reservationDate: undefined,
      reservationDateFrom: undefined,
      reservationDateTo: undefined,
    });
  };

  return {
    attractionsOptions,
    selectedId,
    setQuery,
    handleAttractionComboboxSelect,
    reservationDate,
    reservationDateFrom: query.reservationDateFrom,
    reservationDateTo: query.reservationDateTo,
    handleReservationDateChange,
    setReservationDateFrom,
    setReservationDateTo,
    toggleRangePicker,
    showRangePicker,
    setDateRangeAsThisMonth,
  };
};
