import {
  AttractionGroup,
  AttractionGroupSelect,
  Button,
  Form,
  FormItem,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { AvailabilitySource } from "../../../types";
import { useAvailabilitySourceSettings } from "./use-availability-source-settings";

interface AvailabilitySourceSettingsProps {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const AvailabilitySourceSettings = ({
  attractionId,
  attractionGroups,
}: AvailabilitySourceSettingsProps) => {
  const {
    form,
    onSubmit,
    isPending,
    availabilitySource,
    canAnyGroupBeChangedToExternal,
    sourceChangeableAttractionGroups,
  } = useAvailabilitySourceSettings(attractionId, attractionGroups);

  if (!canAnyGroupBeChangedToExternal) return null;

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Active source settings</SettingCard.Title>
        <SettingCard.Description>
          Switch between internal and external availability source.
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="groupId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Group</FormItem.Label>
                    <FormItem.Control>
                      <AttractionGroupSelect
                        attractionGroups={sourceChangeableAttractionGroups}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="availabilitySource"
              render={() => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Availability source</FormItem.Label>
                    <FormItem.Control>
                      <FormItem.Row className="gap-0">
                        <Button
                          onClick={() =>
                            form.setValue(
                              "availabilitySource",
                              AvailabilitySource.INTERNAL,
                            )
                          }
                          variant={
                            availabilitySource === AvailabilitySource.INTERNAL
                              ? "positive"
                              : "default"
                          }
                          type="button"
                        >
                          Internal
                        </Button>
                        <Button
                          onClick={() =>
                            form.setValue(
                              "availabilitySource",
                              AvailabilitySource.EXTERNAL,
                            )
                          }
                          variant={
                            availabilitySource === AvailabilitySource.EXTERNAL
                              ? "positive"
                              : "default"
                          }
                          type="button"
                        >
                          External
                        </Button>
                      </FormItem.Row>
                    </FormItem.Control>
                  </FormItem.Row>
                </FormItem>
              )}
            />
          </Form.Fields>

          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change Availability source.
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
