import { zodResolver } from "@hookform/resolvers/zod";
import {
  AttractionGroup,
  AttractionVariantDetailedResponse,
} from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAttractionVariant } from "../../../data-access";
import { useAttractionVariants } from "../../../hooks";

const TimeSettingsSchema = z.object({
  variantId: z.number().nonnegative(),
  timeStart: z.string(),
  timeFinish: z.string(),
  timeInterval: z.coerce.number().nonnegative(),
});

type TimeSettingsSchema = z.infer<typeof TimeSettingsSchema>;

// eslint-disable-next-line max-lines-per-function
export const useTimeSettings = (
  attractionId: number,
  attractionGroups: AttractionGroup[],
) => {
  const { isTimed, timedAttractionGroups } =
    extractTimedGroups(attractionGroups);

  const { updateVariant, isPending } = useUpdateAttractionVariant();

  const form = useForm<TimeSettingsSchema>({
    resolver: zodResolver(TimeSettingsSchema),
  });

  const { handleSubmit, watch, setValue } = form;

  const onSubmit = handleSubmit(({ variantId, ...timeSettings }) => {
    updateVariant({
      attractionId,
      variantId,
      body: { timeSettings },
    });
  });

  const variantId = watch("variantId");
  const onVariantChange = (
    selectedVariant: AttractionVariantDetailedResponse,
  ) => {
    const {
      timeSettings: { timeStart, timeFinish, timeInterval },
    } = selectedVariant;

    console.log(selectedVariant.timeSettings);

    if (!timeStart || !timeFinish || !timeInterval) {
      return;
    }

    setValue("timeStart", timeStart);
    setValue("timeFinish", timeFinish);
    setValue("timeInterval", timeInterval);
  };

  useAttractionVariants({
    variantId,
    attractionGroups,
    onVariantChange,
  });

  return {
    form,
    onSubmit,
    isPending,
    timedAttractionGroups,
    isTimed,
  };
};

function extractTimedGroups(attractionGroups: AttractionGroup[]) {
  const timedAttractionGroups: AttractionGroup[] = [];

  for (const group of attractionGroups) {
    const filteredVariants = group.attractionVariants.filter(
      ({ timeSettings: { timed } }) => {
        return timed;
      },
    );

    if (filteredVariants.length > 0) {
      timedAttractionGroups.push({
        ...group,
        attractionVariants: filteredVariants,
      });
    }
  }

  const isTimed = timedAttractionGroups.length > 0;

  return { isTimed, timedAttractionGroups };
}
