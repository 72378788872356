import {
  DetailRowProps,
  PropsWithClassName,
  cn,
  routes,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode, DateFormatter } from "@twocontinents/shared";
import { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";

import { FinancialLog } from "../types";

export const useFinancialLogDetails = (financialLog: FinancialLog) => {
  const details: (DetailRowProps & PropsWithClassName)[] = [
    {
      label: "Id",
      content: financialLog.id.toString(),
    },
    {
      label: "Source",
      content: financialLog.source,
    },
    {
      label: "Cause",
      content:
        financialLog.childFinancialLogs.at(-1)?.cause ?? financialLog.cause,
    },
    {
      label: "Value",
      content: `${financialLog.finalValue} ${financialLog.currency}`,
    },
    {
      label: "Value in PLN",
      content: `${financialLog.finalPlnValue} ${CurrencyCode.PLN}`,
      className: cn({
        "text-green-500": financialLog.finalValue > 0,
        "text-red-500": financialLog.finalValue < 0,
        "!text-slate-200": financialLog.finalPlnValue === 0,
      }),
    },
    {
      label: "Category",
      content: financialLog.category ?? "N/A",
    },
    {
      label: "Type",
      content: financialLog.finalValue > 0 ? "Income" : "Cost",
    },
    {
      label: "Settled",
      content: financialLog.settled ? "Yes" : "No",
    },
    {
      label: "Settle date",
      content: DateFormatter.formatToDDMMYYYY(financialLog.settleDate),
    },
    {
      label: "Created at",
      content: DateFormatter.formatToDDMMYYYYHHmm(financialLog.createdAt),
    },
    {
      label: "Updated at",
      content: DateFormatter.formatToDDMMYYYYHHmm(financialLog.updatedAt),
    },

    ...getOrderDetailRow(financialLog),
    ...getReservationDetailRow(financialLog),
    ...getBookingDetailRow(financialLog),
    ...getTourDetailRow(financialLog),
    ...getNoteDetailRow(financialLog),
  ];

  return {
    details,
    getFinancialLogLabel,
    getFinancialLogContent,
    getFinancialLogClassName,
  };
};

const getFinancialLogLabel = (
  sequence: number,
  financialLog: FinancialLog,
): JSX.Element => {
  return (
    <div className="flex flex-row gap-4">
      {sequence}. {financialLog.cause}
      {financialLog.notes && (
        <h5 className="text-gray-500">{financialLog.notes}</h5>
      )}
    </div>
  );
};

const getDetailRow = (label: string, content: ReactNode, href?: string) => {
  if (content && href) {
    return [
      {
        label,
        content: (
          <Link className={"font-semibold"} to={href}>
            {content}
          </Link>
        ),
      },
    ];
  }

  return [];
};

const getOrderDetailRow = (financialLog: FinancialLog) => {
  const orderId = financialLog?.metadata?.referredOrderId;

  if (!orderId) {
    return [];
  }

  const orderUrl = isAttraction(financialLog)
    ? routes.attractions.orders.show.url(orderId)
    : routes.stays.orders.show.url(orderId);

  return getDetailRow("Order ID", `Order #${orderId}`, orderUrl);
};

const getReservationDetailRow = (financialLog: FinancialLog) => {
  const orderId = financialLog?.metadata?.referredOrderId ?? -1;
  const attractionName = financialLog?.metadata?.attractionName;
  const attractionVariantName = financialLog?.metadata?.attractionVariantName;

  if (!isAttraction(financialLog)) {
    return [];
  }

  const orderUrl = routes.attractions.orders.show.url(orderId);

  return getDetailRow(
    "Attraction reservation",
    `${attractionName} / ${attractionVariantName}`,
    orderUrl,
  );
};

const getBookingDetailRow = (financialLog: FinancialLog) => {
  const orderId = financialLog?.metadata?.referredOrderId;
  const apartmentName = financialLog?.metadata?.apartmentName;
  const apartmentInternalNumber =
    financialLog?.metadata?.apartmentInternalNumber;

  const isBooking = orderId && apartmentName && apartmentInternalNumber;

  if (!isBooking) {
    return [];
  }

  const orderUrl = routes.stays.orders.show.url(orderId);

  return getDetailRow(
    "Booking",
    `${apartmentName} / ${apartmentInternalNumber}`,
    orderUrl,
  );
};

const getTourDetailRow = (financialLog: FinancialLog) => {
  const tourId = financialLog?.metadata?.referredTourId;

  if (!tourId) {
    return [];
  }

  return getDetailRow(
    "Tour ID",
    `Tour #${tourId}`,
    routes.attractions.tours.show.url(tourId),
  );
};

const getNoteDetailRow = (financialLog: FinancialLog) => {
  const note =
    financialLog.childFinancialLogs.at(-1)?.notes ?? financialLog.notes;

  if (!note) {
    return [];
  }

  return [
    {
      label: "Note",
      content: note,
    },
  ];
};

const getFinancialLogContent = (financialLog: FinancialLog) => {
  return `${financialLog.plnValue} ${CurrencyCode.PLN}`;
};

const getFinancialLogClassName = (financialLog: FinancialLog) => {
  return cn({
    "text-green-500": financialLog.plnValue > 0,
    "text-red-500": financialLog.plnValue < 0,
    "text-slate-200": financialLog.plnValue === 0,
  });
};

const isAttraction = (financialLog: FinancialLog) => {
  return (
    financialLog?.metadata?.referredOrderId &&
    financialLog?.metadata?.attractionName &&
    financialLog?.metadata?.attractionVariantName
  );
};
