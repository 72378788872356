import { Tour } from "@twocontinents/dashboard/attractions/shared";
import {
  AlertDialog,
  Button,
  buttonVariants,
} from "@twocontinents/dashboard/shared";

import { useDeleteParticipantsDialog } from "./use-delete-tour-participants-dialog";

interface Props {
  tour: Tour;
  participantIds: number[];
  resetSelectedParticipants: () => void;
}

export const DeleteTourParticipantsDialog = ({
  tour,
  participantIds,
  resetSelectedParticipants,
}: Props) => {
  const {
    isPending,
    onSubmit,
    openAlertDialog,
    dialogDescriptionText,
    dialogTitleText,
    dialogTriggerText,
  } = useDeleteParticipantsDialog(
    tour,
    participantIds,
    resetSelectedParticipants,
  );

  return (
    <AlertDialog
      open={openAlertDialog.value}
      onOpenChange={(open) => openAlertDialog.setValue(open)}
    >
      <AlertDialog.Trigger asChild>
        <Button variant="destructive">{dialogTriggerText}</Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>{dialogTitleText}</AlertDialog.Title>
          <AlertDialog.Description>
            {dialogDescriptionText}
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer>
          <AlertDialog.Cancel
            className={buttonVariants({ variant: "outline" })}
          >
            Cancel
          </AlertDialog.Cancel>

          <Button
            isLoading={isPending}
            onClick={onSubmit}
            disabled={isPending}
            variant="destructive"
          >
            Remove
          </Button>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
