/* eslint-disable max-lines-per-function */
import {
  CompanyDetails,
  CustomerDetails,
  InvoiceType,
} from "@twocontinents/shared";

import { UpdateCustomerEmailDialog } from "./update-customer-email-dialog";

export const useCustomerDetailsSection = (
  customerDetails: CustomerDetails,
  orderId: number,
  companyDetails?: CompanyDetails,
  invoiceType?: InvoiceType,
  travelAgencyId?: number,
  agentOrderReferenceId?: string,
) => {
  const {
    street: customerStreet,
    city: customerCity,
    email,
    name,
    countryIsoCode,
    postalCode: customerPostalCode,
    locale,
    surname,
    phoneNumber,
    ipAddress,
  } = customerDetails;

  const {
    nip: companyNip,
    name: companyName,
    city: companyCity,
    postalCode: companyPostalCode,
    street: companyStreet,
  } = companyDetails ?? {};

  const city = customerCity || companyCity;
  const street = customerStreet || companyStreet;
  const postalCode = customerPostalCode || companyPostalCode;

  const detailsRows = [
    {
      label: "Full name",
      content: `${name} ${surname}`,
    },
    {
      label: "Locale",
      content: locale,
    },
    {
      label: "Country",
      content: countryIsoCode,
    },
    city
      ? {
          label: "City",
          content: city,
        }
      : undefined,
    street
      ? {
          label: "Street",
          content: street,
        }
      : undefined,
    postalCode
      ? {
          label: "Postal code",
          content: postalCode,
        }
      : undefined,
    {
      label: "Phone number",
      content: phoneNumber,
    },
    {
      label: (
        <>
          Email <UpdateCustomerEmailDialog email={email} orderId={orderId} />
        </>
      ),
      content: email,
    },
    ipAddress
      ? {
          label: "IP",
          content: ipAddress,
        }
      : undefined,
  ];

  if (invoiceType) {
    detailsRows.push(
      {
        label: "Invoice",
        content: `Yes`,
      },
      {
        label: "Invoice type",
        content: invoiceType,
      },
    );
  }

  if (companyNip && companyName) {
    detailsRows.push(
      {
        label: "Company name",
        content: companyName,
      },
      {
        label: "VAT number",
        content: companyNip,
      },
    );
  }

  if (agentOrderReferenceId) {
    detailsRows.push({
      label: "Agent order reference ID",
      content: agentOrderReferenceId,
    });
  }

  const hasBeenBoughtByAgent = Boolean(travelAgencyId);

  return {
    detailsRows: detailsRows.filter((detail) => detail !== undefined),
    hasBeenBoughtByAgent,
  };
};
