import { useQuery } from "@tanstack/react-query";
import {
  NOT_SELECTED_ID,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";

const queryKey = (orderId: number) => ["order", orderId];

export const useGetOrder = (orderId: number) => {
  const apiClient = usePrivateApiClient();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/orders/{id}", {
          params: { path: { id: orderId } },
        })
        .then((response) => response.data),
    queryKey: queryKey(orderId),
    enabled: orderId !== NOT_SELECTED_ID,
  });

  return {
    order: data,
    isLoading,
  };
};
