import {
  Button,
  DetailsTable,
  OrderStatusesSwitches,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { BookingOrder } from "../types";
import { useBookingOrderDetails } from "./use-booking-order-details";

interface BookingOrderDetailsProps {
  order: BookingOrder;
}

export const BookingOrderDetails = ({ order }: BookingOrderDetailsProps) => {
  const {
    formattedPurchasedTime,
    orderDetailsRows,
    handlePaymentLinkCopyButtonClick,
  } = useBookingOrderDetails(order);
  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>
          Order #{order.id} from {formattedPurchasedTime}
        </SettingCard.Title>
        <SettingCard.Description>
          Review order details and change its statuses.
        </SettingCard.Description>
      </SettingCard.Header>

      <DetailsTable>
        {orderDetailsRows.map((row) => (
          <DetailsTable.Row key={row.label} {...row} />
        ))}
        {order.paymentUrl && (
          <DetailsTable.Row
            label="Payment Link"
            content={
              <Button
                variant={"outline"}
                onClick={() =>
                  handlePaymentLinkCopyButtonClick(order.paymentUrl ?? "")
                }
              >
                Copy Link
              </Button>
            }
          />
        )}
      </DetailsTable>

      <OrderStatusesSwitches order={order} />
    </SettingCard>
  );
};
