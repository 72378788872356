import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useBlackListQueryParams } from "../hooks/use-black-list-entries-query-params";
import { useInvalidateGetBlackListEntries } from "./get-black-list-entries";

type CreateBlackListEntry =
  components["schemas"]["CreateBlackListEntryRequest"];

export const useCreateBlackListEntry = () => {
  const apiClient = usePrivateApiClient();
  const { query } = useBlackListQueryParams();
  const { invalidate } = useInvalidateGetBlackListEntries();

  const { mutate, isPending } = useMutation({
    mutationFn: async (request: CreateBlackListEntry) => {
      return await apiClient.POST("/private/black-list/", {
        body: request,
      });
    },
    onSuccess: async () => {
      toast.success("Black list entry has been added");
      await invalidate(query);
    },
    onError: () => {
      toast.error("Could not add black list entry");
    },
  });

  return { createBlackListEntry: mutate, isPending };
};
