import {
  AttractionGroup,
  Button,
  Layout,
} from "@twocontinents/dashboard/shared";
import { lazy, useState } from "react";

import { AgeGroupsSettings } from "./age-groups-settings";
import { AgeGroupsTicketsSettings } from "./age-groups-tickets-settings";
import { DescriptionSettings } from "./description-settings";
import { FormSequenceSettings } from "./form-sequence-settings";
import { MetadataSettings } from "./metadata-settings";
import { TimePriorToReservationSettings } from "./time-prior-to-reservation-settings";
import { TimeSettings } from "./time-settings";
import { TourMessageSettings } from "./tour-message-settings";

const GeofenceSettings = lazy(() => import("./geofence-settings"));

interface VariantsSettingsProps {
  attractionGroups: AttractionGroup[];
  attractionId: number;
}

export const VariantsSettings = ({
  attractionGroups,
  attractionId,
}: VariantsSettingsProps) => {
  const [showGeoFence, setShowGeoFence] = useState(false);
  const isAnyVariantGeofenced = attractionGroups
    .flatMap((group) => group.attractionVariants)
    .some((variant) => variant.geofenced);

  return (
    <Layout.Content>
      <DescriptionSettings
        attractionId={attractionId}
        attractionGroups={attractionGroups}
      />
      <TourMessageSettings
        attractionId={attractionId}
        attractionGroups={attractionGroups}
      />

      <TimePriorToReservationSettings
        attractionId={attractionId}
        attractionGroups={attractionGroups}
      />
      <TimeSettings
        attractionId={attractionId}
        attractionGroups={attractionGroups}
      />
      <AgeGroupsSettings
        attractionId={attractionId}
        attractionGroups={attractionGroups}
      />
      <AgeGroupsTicketsSettings
        attractionId={attractionId}
        attractionGroups={attractionGroups}
      />
      <MetadataSettings
        attractionGroups={attractionGroups}
        attractionId={attractionId}
      />
      <FormSequenceSettings
        attractionId={attractionId}
        attractionGroups={attractionGroups}
      />
      {isAnyVariantGeofenced && (
        <>
          <Button onClick={() => setShowGeoFence((prevState) => !prevState)}>
            {showGeoFence ? "Hide" : "Show"} Geofence settings
          </Button>
          {showGeoFence && (
            <GeofenceSettings attractionGroups={attractionGroups} />
          )}
        </>
      )}
    </Layout.Content>
  );
};
