import {
  Input,
  Layout,
  Pagination,
  StatusIndicator,
  Table,
} from "@twocontinents/dashboard/shared";

import { useGetBlackListEntries } from "../data-access";
import { useBlackListQueryParams } from "../hooks";
import { CreateBlacklistEntryModal } from "./add-blocked-entry-modal";
import { UpdateBlackListEntryAlertDialog } from "./update-black-list-entry-alert-dialog";

export const BlacklistManagement = () => {
  const { query, setQuery } = useBlackListQueryParams();
  const { blackListEntries, totalPages, totalElements } =
    useGetBlackListEntries(query);

  return (
    <Layout
      header={{
        title: "Blacklist management",
        description: `Manage blacklist here`,
      }}
    >
      <div className="flex gap-4">
        <Input
          name="ipAddress"
          placeholder="Search by ip address"
          className="mb-8"
          value={query.ipAddress}
          onChange={(e) => {
            setQuery({ ...query, ipAddress: e.target.value });
          }}
        />
        <CreateBlacklistEntryModal />
      </div>

      <Table className="w-full  bg-slate-950 ">
        <Table.Header className="w-full rounded-3xl ">
          <Table.Row className="border-slate-700">
            <Table.Head className="py-5 w-1/3">Ip address</Table.Head>
            <Table.Head className="py-5 w-1/3">Blocked</Table.Head>
            <Table.Head className="py-5 w-1/3">Action</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body className="w-full relative">
          {blackListEntries.map((entry, i) => {
            return (
              <Table.Row key={entry.id}>
                <Table.Cell>{entry.ipAddress}</Table.Cell>
                <Table.Cell>
                  <StatusIndicator
                    status={entry.blacklisted ? "negative" : "positive"}
                  >
                    {entry.blacklisted ? "Blocked" : "Not blocked"}
                  </StatusIndicator>
                </Table.Cell>
                <Table.Cell onClick={(e) => e.stopPropagation()}>
                  <UpdateBlackListEntryAlertDialog entry={entry} />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {totalElements > 0 && <Pagination totalPages={totalPages} />}
    </Layout>
  );
};
