import { components } from "@twocontinents/shared";

export type AttractionCoupon =
  components["schemas"]["AttractionCouponDetailsResponse"];
export type ApartmentCoupon =
  components["schemas"]["ApartmentCouponDetailsResponse"];

export type PackageHolidayCoupon =
  components["schemas"]["PackageHolidayCouponDetailsResponse"];

export type Coupon = AttractionCoupon | ApartmentCoupon | PackageHolidayCoupon;

export const isAttractionCoupon = (
  coupon: Coupon,
): coupon is AttractionCoupon => {
  return "attractionVariants" in coupon;
};

export const isApartmentCoupon = (
  coupon: Coupon,
): coupon is ApartmentCoupon => {
  return "apartments" in coupon;
};

export const isPackageHolidayCoupon = (
  coupon: Coupon,
): coupon is PackageHolidayCoupon => {
  return "packageHolidays" in coupon;
};
