import { zodResolver } from "@hookform/resolvers/zod";
import { useGetAttractionVariantAgeGroups } from "@twocontinents/dashboard/attractions/shared";
import {
  NOT_SELECTED_ID,
  useGetAttraction,
  useGetAttractions,
} from "@twocontinents/dashboard/shared";
import { DateFormatter, Gender } from "@twocontinents/shared";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useGenerateTicket } from "../../data-access";

export const GenerateTicketFormSchema = z
  .object({
    date: z.coerce.date(),
    attractionId: z.number(),
    variantId: z.number(),
    ageGroups: z.array(
      z.object({
        id: z.coerce.number(),
        amount: z.coerce.number(),
      }),
    ),
    customerDetails: z.object({
      gender: z.nativeEnum(Gender),
      name: z.string(),
      surname: z.string(),
    }),
  })
  .superRefine((data, ctx) => {
    if (!data.ageGroups.some((ag) => ag.amount > 0)) {
      ctx.addIssue({
        path: ["ageGroups.0.amount"],
        message: "At least one age group must have a positive amount",
        code: "custom",
      });
    }
  });

type GenerateTicketFormValues = z.infer<typeof GenerateTicketFormSchema>;

export const useGenerateTicketForm = () => {
  const form = useForm<GenerateTicketFormValues>({
    resolver: zodResolver(GenerateTicketFormSchema),
    defaultValues: {
      customerDetails: {
        gender: Gender.MALE,
        name: "Two",
        surname: "Continents",
      },
    },
  });

  const attractionId = form.watch("attractionId");
  const attractionVariantId = form.watch("variantId");
  const formAgeGroups = form.watch("ageGroups");

  const { attractions } = useGetAttractions();

  const currentAttraction = attractions.find(
    (attraction) => attraction.id === attractionId,
  );

  const { attraction: currentAttractionDetails } = useGetAttraction(
    currentAttraction?.id ?? NOT_SELECTED_ID,
  );

  const variants =
    currentAttractionDetails?.attractionGroups.flatMap(
      (group) => group.attractionVariants,
    ) ?? [];

  console.log(form.formState.errors);
  console.log(form.formState.isValid);

  const currentVariant = variants?.find(
    (variant) => variant.id === attractionVariantId,
  );

  const ageGroups =
    useGetAttractionVariantAgeGroups(currentVariant?.id ?? NOT_SELECTED_ID)
      ?.ageGroups ?? [];

  const filteredAgeGroups = useMemo(
    () => ageGroups.filter((ag) => ag.providerPrice > 0),
    [ageGroups],
  );

  useEffect(() => {
    form.setValue(
      "ageGroups",
      filteredAgeGroups?.map((ag) => ({ id: ag.id, amount: 0 })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAgeGroups, form]);

  const { generateTicket, isPending, ticketUrl } = useGenerateTicket();

  const handleFormSubmit = form.handleSubmit(async (values) => {
    if (formAgeGroups.length > 0) {
      generateTicket({
        variantId: attractionVariantId,
        body: {
          date: DateFormatter.formatToYYYYMMDD(values.date),
          customerDetails: {
            ...values.customerDetails,
            email: "kontakt@twocontinents.com",
            phoneNumber: "+48 123 456 789",
          },
          ageGroups: formAgeGroups,
        },
      });
    }
  });

  return {
    form,
    handleFormSubmit,
    isPending,
    ticketUrl,
    ageGroups: filteredAgeGroups,
    attractions: attractions ?? [],
    variants,
  };
};
