import { useMutation } from "@tanstack/react-query";
import {
  toast,
  useInvalidateGetAttraction,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type Body =
  components["schemas"]["UpdateAttractionGroupAvailabilitySourceRequest"];

interface Props {
  attractionId: number;
  groupId: number;
  body: Body;
}

export const useUpdateAttractionGroupAvailabilitySource = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetAttraction();

  const { mutate: updateAttractionGroupAvailabilitySource, isPending } =
    useMutation({
      mutationFn: ({ groupId, body }: Props) =>
        apiClient
          .PATCH(`/private/attractions/groups/{id}/availability-source`, {
            params: {
              path: { id: groupId },
            },
            body,
          })
          .then((response) => response.data),

      onSuccess: async (_, { attractionId }) => {
        toast.success(
          "Group availability source has been updated successfully.",
        );
        await invalidate(attractionId);
      },
      onError: () => {
        toast.error("Group availability source could not be updated");
      },
    });

  return { updateAttractionGroupAvailabilitySource, isPending };
};
