import { usePaginationQueryParams } from "@twocontinents/dashboard/shared";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

export const useBlackListQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    ipAddress: withDefault(StringParam, undefined),
  });

  const { size, page } = usePaginationQueryParams();

  return {
    query: {
      ...query,
      size,
      page,
    },
    setQuery,
  };
};
