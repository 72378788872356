import {
  Tour,
  useInvalidateGetTour,
} from "@twocontinents/dashboard/attractions/shared";
import { toast } from "@twocontinents/dashboard/shared";
import { useBoolean } from "usehooks-ts";

import { useDeleteTourParticipants } from "../../data-access";

export const useDeleteParticipantsDialog = (
  tour: Tour,
  participantIds: number[],
  resetSelectedParticipants: () => void,
) => {
  const openAlertDialog = useBoolean(false);

  const { invalidate } = useInvalidateGetTour();

  const { deleteTourParticipants, isPending } = useDeleteTourParticipants();

  const onSubmit = () => {
    deleteTourParticipants(
      {
        body: {
          participantIds,
        },
      },
      {
        onSuccess() {
          toast.success(
            `Removed ${participantIds.length} participant(s) from the tour`,
          );
          resetSelectedParticipants();
          void invalidate(tour.id);
          openAlertDialog.setFalse();
        },
        onError() {
          resetSelectedParticipants();
          toast.error("Failed to remove participants from the tour");
        },
      },
    );
  };

  const isBatchRemoval = participantIds.length > 1;

  const dialogTriggerText = isBatchRemoval ? "Remove participants" : "Remove";
  const dialogTitleText = `Remove ${
    isBatchRemoval ? "participants" : "participant"
  } from the tour`;
  const dialogDescriptionText = `Are you sure you want to remove ${
    isBatchRemoval ? "these participants" : "this participant"
  } from the tour?`;

  return {
    openAlertDialog,
    onSubmit,
    isPending,
    dialogTriggerText,
    dialogTitleText,
    dialogDescriptionText,
  };
};
