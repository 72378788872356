import {
  AttractionCombobox,
  Input,
  Layout,
  Pagination,
  SingleOrRangeDatePicker,
  Table,
  buttonVariants,
  routes,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { Link } from "react-router-dom";

import { DateTypeKey, NumberTypeKey, StringTypeKey } from "../hooks";
import { useTicketsHistory } from "./use-tickets-history";

export const TicketsHistory = () => {
  const {
    tickets,
    pagination,
    showRangePicker,
    toggleRangePicker,
    setQuery,
    query,
    handleTextChange,
    handleNumberChange,
    handleDateFieldChange,
    attractionsOptions,
    tourDateFrom,
    tourDateTo,
    tourDate,
    toggleSortDirection,
  } = useTicketsHistory();

  return (
    <Layout
      header={{
        title: "TicketsHistory",
        description: `Browse your tickets history.`,
      }}
    >
      <div className="gap-5 flex  items-start border-slate-700 flex-wrap">
        <div className="flex  gap-4">
          <AttractionCombobox
            attractions={attractionsOptions}
            selectedId={query.attractionId}
            setSelectedOption={(value) =>
              setQuery({
                attractionId: value?.id === -1 ? undefined : value?.id,
              })
            }
          />
        </div>
        <div className="flex gap-4 flex-wrap">
          <Input
            name="firstName"
            className="w-44"
            placeholder="Name"
            value={query.customerName}
            onChange={handleTextChange("customerName" as StringTypeKey)}
          />
          <Input
            name="lastName"
            className="w-44"
            placeholder="Surname"
            value={query.customerSurname}
            onChange={handleTextChange("customerSurname" as StringTypeKey)}
          />

          <Input
            name="id"
            className="w-44"
            placeholder="Order ID"
            type="number"
            value={query.orderId}
            onChange={handleNumberChange("orderId" as NumberTypeKey)}
          />
        </div>
        <div className="flex flex-col gap-2 items-center justify-center">
          <SingleOrRangeDatePicker
            showRangePicker={showRangePicker}
            toggleRangePicker={toggleRangePicker}
            dateFrom={tourDateFrom}
            setDateFrom={handleDateFieldChange("tourDateFrom" as DateTypeKey)}
            dateTo={tourDateTo}
            setDateTo={handleDateFieldChange("tourDateTo" as DateTypeKey)}
            date={tourDate}
            setDate={handleDateFieldChange("tourDate" as DateTypeKey)}
            time={undefined}
          />
        </div>
      </div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.Head className="py-2">ID</Table.Head>
            <Table.Head
              className="py-2 cursor-pointer min-w-32"
              onClick={toggleSortDirection}
            >
              Created At {query.sortDirection === "ASC" ? "▲" : "▼"}
            </Table.Head>
            <Table.Head className="py-2">Tour Date</Table.Head>
            <Table.Head className="py-2">Name</Table.Head>
            <Table.Head className="py-2">Surname</Table.Head>
            <Table.Head className="py-2">Attraction</Table.Head>
            <Table.Head className="py-2">Variant</Table.Head>
            <Table.Head className="py-2">Age groups</Table.Head>
            <Table.Head className="py-2">Order</Table.Head>
            <Table.Head className="py-2">Open</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tickets.map((ticket) => (
            <Table.Row key={ticket.id}>
              <Table.Cell>{ticket.id}</Table.Cell>
              <Table.Cell>
                {DateFormatter.formatToDDMMYYYYHHmm(ticket.createdAt)}
              </Table.Cell>
              <Table.Cell>
                {DateFormatter.formatToYYYYMMDD(ticket.tourDate)}
              </Table.Cell>
              <Table.Cell>{ticket.customerName}</Table.Cell>
              <Table.Cell>{ticket.customerSurname}</Table.Cell>
              <Table.Cell>{ticket.ageGroups?.[0]?.attractionName}</Table.Cell>
              <Table.Cell>
                {ticket.ageGroups?.[0]?.attractionVariantName}
              </Table.Cell>
              <Table.Cell>
                {ticket.ageGroups.map((ag) => (
                  <p key={ag.ageGroupId}>
                    {ag.ageGroupName} x{ag.amount}
                  </p>
                ))}
              </Table.Cell>
              <Table.Cell>
                {ticket.orderId ? (
                  <Link
                    to={routes.attractions.orders.show.url(ticket.orderId)}
                    className={buttonVariants({ variant: "outline" })}
                  >
                    #{ticket.orderId}
                  </Link>
                ) : (
                  "N/A"
                )}
              </Table.Cell>
              <Table.Cell>
                <Link
                  target="_blank"
                  to={ticket.url}
                  className={buttonVariants({ variant: "outline" })}
                >
                  Open
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Pagination totalPages={pagination?.totalPages ?? 0} />
    </Layout>
  );
};
