import { zodResolver } from "@hookform/resolvers/zod";
import { FinancialLogCategory, toast } from "@twocontinents/dashboard/shared";
import { ApiLocale, CurrencyCode } from "@twocontinents/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  useGeneratePaymentLink,
  useGetAllBusinessPartners,
} from "../../data-access";

const PaymentLinkGeneratorFormSchema = z.object({
  name: z.string().min(3),
  price: z.coerce.number().positive(),
  currency: z.nativeEnum(CurrencyCode),
  quantity: z.coerce.number().positive(),
  customerName: z.string().min(3),
  customerSurname: z.string().min(3),
  customerEmail: z.string().email(),
  locale: z.nativeEnum(ApiLocale),
  category: z.nativeEnum(FinancialLogCategory),
  notes: z.string().min(3).optional(),
  businessPartnerCommissions: z
    .array(
      z.object({
        businessPartnerId: z.coerce.number(),
        commission: z.coerce.number().nonnegative(),
      }),
    )
    .superRefine((commissions, ctx) => {
      const totalCommission = commissions.reduce(
        (acc, { commission }) => acc + commission,
        0,
      );

      const incorrectCommission = totalCommission !== 1;

      if (incorrectCommission) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["businessPartnerCommissions", "commission"],
          message: "Total commission must be equal to 1",
        });
      }
    }),
});

type PaymentLinkGeneratorForm = z.infer<typeof PaymentLinkGeneratorFormSchema>;

export const useGeneratePaymentLinkForm = () => {
  const form = useForm<PaymentLinkGeneratorForm>({
    resolver: zodResolver(PaymentLinkGeneratorFormSchema),
    defaultValues: {
      currency: CurrencyCode.PLN,
      locale: ApiLocale.PL,
    },
  });

  const { generatePaymentLink, paymentLink, isPending } =
    useGeneratePaymentLink();
  const { businessPartners } = useGetAllBusinessPartners();

  const handleFormSubmit = form.handleSubmit((data) => {
    generatePaymentLink(data);
  });

  const locale = form.watch("locale");

  useEffect(() => {
    form.setValue(
      "businessPartnerCommissions",
      businessPartners.map(({ id }, _, arr) => ({
        businessPartnerId: id,
        // get matching commission for selected locale
        commission:
          arr
            .find((bp) => bp.id === id)
            ?.commissionResponses.find((c) => c.locale === locale)
            ?.commission ?? 0,
      })),
    );
    // Dont pass businessPartners to the dependency array, because we want to change commission only if length of businessPartners changes, not value itself
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessPartners.length, form, locale]);

  const handleCopyLinkButtonClick = async () => {
    if (!paymentLink) {
      return;
    }
    await navigator.clipboard.writeText(paymentLink);
    toast.success("Link copied to clipboard");
  };

  return {
    form,
    handleFormSubmit,
    paymentLink,
    isPending,
    businessPartners,
    handleCopyLinkButtonClick,
  };
};
