import { useMutation, useQueryClient } from "@tanstack/react-query";
import { components } from "@twocontinents/shared";

import { toast, usePrivateApiClient } from "../utils";

export type UpdateOrderRequest = components["schemas"]["UpdateOrderRequest"];

export const orderQueryKey = (id: number) => {
  return ["order", id];
};

export const useUpdateOrder = (id: number) => {
  const apiClient = usePrivateApiClient();
  const invalidateGetOrder = useInvalidateGetOrder();

  const { mutate, isPending } = useMutation({
    mutationFn: (request: UpdateOrderRequest) => {
      return apiClient.PATCH("/private/orders/{id}", {
        body: request,
        params: {
          path: {
            id,
          },
        },
      });
    },
    onSuccess: async () => {
      await invalidateGetOrder(id);
      toast.success("Order has been updated.");
    },
    onError: () => {
      toast.error("An error occurred while updating the order.");
    },
  });

  return {
    updateOrder: mutate,
    isPending,
  };
};

export const useInvalidateGetOrder = () => {
  const queryClient = useQueryClient();
  return async (id: number) => {
    await queryClient.invalidateQueries({
      queryKey: orderQueryKey(id),
    });
  };
};
