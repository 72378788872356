import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import { z } from "zod";

import { useCancelFinancialLog } from "../../data-access";

const CancelFinancialLogFormSchema = z.object({
  cause: z.string().min(3),
});

type CancelFinancialLogFormSchema = z.infer<
  typeof CancelFinancialLogFormSchema
>;

export const useCancelFinancialLogModal = (id: number) => {
  const { value: isOpened, setTrue: open, setFalse: close } = useBoolean(false);
  const { cancelFinancialLog, isPending } = useCancelFinancialLog(id);

  const form = useForm<CancelFinancialLogFormSchema>({
    resolver: zodResolver(CancelFinancialLogFormSchema),
    mode: "onBlur",
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit((data) => {
    cancelFinancialLog(data);
    form.reset({
      cause: undefined,
    });
    close();
  });

  return {
    form,
    onSubmit,
    isPending,
    isOpened,
    open,
    close,
  };
};
