import { useMutation } from "@tanstack/react-query";
import { components } from "@twocontinents/shared";

import { toast, usePrivateApiClient } from "../utils";
import { useInvalidateGetOrder } from "./update-order";

export type UpdateOrderCustomerDetailsRequest =
  components["schemas"]["UpdateOrderCustomerDetailsRequest"];

export const useUpdateCustomerDetails = (id: number) => {
  const apiClient = usePrivateApiClient();
  const invalidateGetOrder = useInvalidateGetOrder();

  const { mutate, isPending } = useMutation({
    mutationFn: (request: UpdateOrderCustomerDetailsRequest) => {
      return apiClient.PATCH("/private/orders/{id}/customer", {
        body: request,
        params: {
          path: {
            id,
          },
        },
      });
    },
    onSuccess: async () => {
      await invalidateGetOrder(id);
      toast.success("Customer details have been updated.");
    },
    onError: () => {
      toast.error("An error occurred while updating the customer details.");
    },
  });

  return {
    updateCustomer: mutate,
    isPending,
  };
};
