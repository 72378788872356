import { useMutation } from "@tanstack/react-query";
import {
  toast,
  useInvalidateGetPackageHolidays,
  usePrivateApiClient,
} from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

type UpdatePackageHolidayRequest =
  components["schemas"]["UpdatePackageHolidayRequest"];

interface Props {
  id: number;
  body: UpdatePackageHolidayRequest;
}

export const useUpdatePackageHoliday = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetPackageHolidays();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ id, body }: Props) => {
      return apiClient
        .PATCH("/private/package-holidays/{id}", {
          params: { path: { id } },
          body,
        })
        .then((response) => response.data);
    },
    onSuccess: async (_) => {
      await invalidate();
      toast.success("Package holiday has been updated");
    },
    onError: () => {
      toast.error("Could not update package holiday");
    },
  });

  return { updatePackageHoliday: mutate, isPending };
};
