import { CouponsTab } from "./coupons-tab";
import { usePackageHolidaysCouponsTab } from "./coupons-table/use-package-holidays-coupons-tab";

export const PackageHolidaysCouponsTab = () => {
  const { arePaginatedCouponsLoading, coupons, pagination } =
    usePackageHolidaysCouponsTab();

  return (
    <CouponsTab
      coupons={coupons}
      totalPages={pagination?.totalPages ?? 0}
      arePaginatedCouponsLoading={arePaginatedCouponsLoading}
    />
  );
};
