import { PropsWithChildren, ReactNode } from "react";

import { PropsWithClassName } from "../types";
import { cn } from "../utils";
import { Skeleton } from "./shadcn";

interface DetailsTableProps extends PropsWithChildren {
  loading?: boolean;
  className?: string;
}

export const DetailsTable = ({ children, className }: DetailsTableProps) => {
  return (
    <div
      className={cn(
        "border border-slate-800 divide-y divide-slate-800 rounded-md",
        className,
      )}
    >
      {children}
    </div>
  );
};

export const DetailsTableSkeleton = () => {
  return (
    <DetailsTable>
      <DetailRowSkeleton />
      <DetailRowSkeleton />
      <DetailRowSkeleton />
      <DetailRowSkeleton />
      <DetailRowSkeleton />
    </DetailsTable>
  );
};

const DetailRowSkeleton = () => {
  return (
    <div className="grid grid-cols-2 gap-4 px-6 py-4 h-20">
      <Skeleton className=" h-full" />
      <Skeleton className=" h-full" />
    </div>
  );
};

export interface DetailRowProps extends PropsWithClassName {
  label: ReactNode;
  content: ReactNode;
}

const DetailRow = ({ label, content, className }: DetailRowProps) => {
  return (
    <div className="py-4 px-6 hover:bg-slate-900 transition flex justify-between items-center">
      <h3 className=" font-medium capitalize">{label}</h3>
      <p className={className}>{content}</p>
    </div>
  );
};

DetailsTable.Row = DetailRow;
