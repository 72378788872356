import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { CalendarExtraOptionAvailability } from "../ui/groups-settings/extra-options-availability-settings/availability-settings/use-extra-options-availability-settings";
import { useInvalidateGetAttractionCrewAvailability } from "./get-attraction-crew-availability";
import { useInvalidateExtraOptionAvailabilities } from "./get-extra-options-availabilities";

// eslint-disable-next-line max-lines-per-function
export const useUpdateExtraOptionAvailabilities = () => {
  const apiClient = usePrivateApiClient();

  const { invalidateCrewAvailability } =
    useInvalidateGetAttractionCrewAvailability();
  const invalidateExtraOptionAvailabilities =
    useInvalidateExtraOptionAvailabilities();

  const { mutate: updateExtraOptionAvailabilities, isPending } = useMutation({
    mutationFn: async ({
      mutatedAvailabilities,
      selectedExtraOptions,
    }: {
      attractionId: number;
      mutatedAvailabilities: CalendarExtraOptionAvailability[];
      selectedExtraOptions: { id: number }[];
    }) => {
      // Group availabilities by groupId
      const groupedByGroupId = Object.groupBy(
        mutatedAvailabilities,
        (availability) => availability.groupId,
      );

      // For each group, create ONE API call that includes all extra options
      const updatePromises = Object.entries(groupedByGroupId).map(
        ([groupId, availabilities]) => {
          // Prepare all availabilities with all extra option IDs in a single array
          const allExtraOptionAvailabilities = selectedExtraOptions.flatMap(
            (option) => {
              const extraOptionId = Number(option.id);

              // Add extraOptionId to each availability
              return (
                availabilities?.map((av) => ({
                  ...av,
                  extraOptionId,
                })) ?? []
              );
            },
          );

          // Make a single API call for this group with all extra option availabilities
          return apiClient.POST(
            `/private/attractions/groups/{id}/extra-options/availability`,
            {
              params: {
                path: { id: Number(groupId) },
              },
              body: {
                extraOptionAvailabilities: allExtraOptionAvailabilities,
              },
            },
          );
        },
      );

      // Execute all group update requests in parallel
      return Promise.all(updatePromises);
    },
    onSuccess: async (data, { attractionId, selectedExtraOptions }) => {
      toast.success("Group availabilities have been updated successfully.");
      await invalidateCrewAvailability(attractionId);
      await invalidateExtraOptionAvailabilities(
        selectedExtraOptions.map((eo) => eo.id),
      );
    },
  });

  return { updateExtraOptionAvailabilities, isPending };
};
